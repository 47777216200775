<template>
	<div class="authPage">
		<div
			class="
				content-top-bar
				d-flex
				justify-content-between
				align-items-center
				bg-white
			"
			:class="{
				'top-bar-fixed p-3': helpers.layout.isMobile(),
				'position-sticky p-4': !helpers.layout.isMobile(),
			}"
		></div>
		<div
			class="mx-auto"
			:class="{
				'w-100': helpers.layout.isMobile(),
				'w-50 pt-40 pb-40': !helpers.layout.isMobile(),
			}"
		>
			<div
				:class="{
					'py-4 px-3': helpers.layout.isMobile(),
				}"
			>
				<h1 class="text-center mb-5rem">
					How would you like to <br />
					sign in?
				</h1>

				<div>
					<router-link
						v-slot="{ navigate }"
						:to="{ name: 'auth.login.email' }"
						custom
						class=""
					>
						<button
							class="
								w-100
								btn btn-outline-dark
								w-icon
								rounded-pill
								mb-3
								justify-content-start
							"
							@click="navigate"
						>
							<span
								class="
									round-icon
									d-flex
									align-items-center
									justify-content-center
								"
							>
								<span class="material-icons-outlined" aria-hidden="true">
									email
								</span>
							</span>
							<span class="button-with-left-icon">Email / Password</span>
						</button>
					</router-link>

					<router-link
						v-slot="{ navigate }"
						:to="{ name: 'auth.login.express' }"
						custom
					>
						<button
							class="
								w-100
								btn btn-outline-dark
								w-icon
								rounded-pill
								mb-3
								justify-content-start
							"
							@click="navigate"
						>
							<span
								class="
									round-icon
									d-flex
									align-items-center
									justify-content-center
								"
							>
								<span class="material-icons-outlined" aria-hidden="true">
									meeting_room
								</span>
							</span>
							<span class="button-with-left-icon">Express Sign In</span>
						</button>
					</router-link>

					<button
						class="
							w-100
							btn btn-outline-dark
							w-icon
							rounded-pill
							mb-3
							justify-content-start
						"
						onclick=" window.open('https://help.intedashboard.com/kb-search-results?term=LTI','_blank')"
					>
						<span
							class="
								round-icon
								d-flex
								align-items-center
								justify-content-center
							"
						>
							<span class="material-icons-outlined" aria-hidden="true">
								extension
							</span>
						</span>
						<span class="button-with-left-icon"> Sign In with LMS (LTI) </span>
					</button>

					<router-link
						v-slot="{ navigate }"
						:to="{ name: 'auth.magic.email' }"
						custom
					>
						<button
							class="
								w-100
								btn btn-outline-dark
								w-icon
								rounded-pill
								mb-4
								justify-content-start
							"
							@click="navigate"
						>
							<span
								class="
									round-icon
									d-flex
									align-items-center
									justify-content-center
								"
							>
								<span class="material-icons-outlined" aria-hidden="true">
									auto_fix_high
								</span>
							</span>
							<span class="button-with-left-icon"> Magic Link </span>
						</button>
					</router-link>

					<div class="text-center">
						<p class="d-inline-block">See our</p>
						<a
							href="https://help.intedashboard.com"
							target="_blank"
							class="fw-bold"
						>
							Help Guides
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { inject, onMounted } from "vue";
import { useRoute } from "vue-router";
export default {
	setup() {
		const route = useRoute();
		const auth = inject("auth");
		const helpers = inject("helpers");
		const $api = inject("$api");

		/*const logout = () => {
			return $api.auth.logout(
				auth.credential().accessToken,
				auth.credential().apiUrl
			);
		};*/
		if (route.name == "auth.logout") {
			/*logout()
				.then(function () {})
				.catch(function (errors) {})
				.finally(() => {
					auth.logout();
				});*/
			auth.logout();
		} else {
			if (auth.isLoggedIn()) {
				/*let url = `${auth.credential().appUrl}?access_token=${
					auth.credential().accessToken
				}&api_url=${auth.credential().apiUrl}`;*/
				window.location.href = auth.credential().appUrl;
			}
		}

		return {
			helpers,
		};
	},
};
</script>
<style lang="scss" scoped>
.container {
	align-self: center;
	display: flex;
}
</style>
